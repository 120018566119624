import React from "react";
import style from "./Button.module.scss";

const Button = (props) => {
  return (
    <div className={style.button}>
      <button {...props}>{props.children}</button>
    </div>
  );
};

export default Button;
