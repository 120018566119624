import axios from "axios";
import { useState } from "react";
import { http } from "../axios";

export const useData = async (url) => {
  let res = await http.get(url);
  if (res && res.data) {
    return res.data;
  }
};
