import logo from "./logo.svg";
import "./App.scss";
import Router from "./components/router";
import Layout from "./components/layout";
import brokerContext from "./context/brokerContext";
import ContextProvider from "./components/contextProvider";
import Counter from "./components/counter";

function App() {

  return (
    <ContextProvider>
      <div className="App">
      <div className="counter">
        <Counter />
      </div>
        <Layout>
          <div className="content">
            <Router />
          </div>
        </Layout>
      </div>
    </ContextProvider>
  );
}

export default App;
