import React from "react";
import style from "./InputField.module.scss";

const InputField = (props) => {
  const MAP = {
    qrcode: (
      <input type={"file"} accept="image/*" capture="environment"></input>
    ),
    text: <input name={props.name} {...props} capture="environment"></input>,
    password: <input type={"password"} name={props.name} {...props}></input>,
    number: <input type={"number"} name={props.name} {...props}></input>,
    date: <input type={"date"} name={props.name} {...props} ></input>,
  };
  return (
    <div className={style.input}>
      <label>{props.placeholder}</label>
      {MAP[props.type]}
      <small className={style.input_help_text}>{props.help_text}</small>
    </div>
  );
};

export default InputField;
