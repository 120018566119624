import React from "react";
import { CONFIG } from "../../const/config";
import style from "./BrokerCard.module.scss";

const BrokerCard = ({ data }) => {
  return (
    <div className={style.card}>
      <div className={style.icon_wrapper}>
        <img src={`${CONFIG.cdnBase}${data.broker_image}`} alt="icon" />
      </div>
      <div className={style.title}>{data.broker_name}</div>
      {(data.action == 'upcomming') && <div className={style.upcomming}>
        <span> Comming Soon </span>
      </div>}
    </div>
  );
};

export default BrokerCard;
