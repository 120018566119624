import React from "react";
import Counter from "../counter";
import Footer from "../footer";
import style from "./Layout.module.scss";

const Layout = (props) => {
  return (
    <div className={style.layout}>
      {props.children}
      <div className={style.footer}>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
