import React from "react";

import { createBrowserRouter, RouterProvider, Route } from "react-router-dom";
import Brokers from "../../pages/brokers";
import Expired from "../../pages/expired";
import Form from "../../pages/form/[slug]";
import Home from "../../pages/home";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/brokers/secured-access/protected",
    element: <Brokers />,
  },
  {
    path: "/form/:slug/login",
    element: <Form />,
  },
  {
    path: "/session/expired",
    element: <Expired />,
  },
]);
const Router = () => {
  return <RouterProvider router={router} />;
};

export default Router;
