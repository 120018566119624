import React, { useEffect, useState } from "react";
import style from "./Counter.module.scss";

const Counter = () => {
  const [seconds, setSeconds] = useState(0);
  console.log("render");
  const startTimer = () => {
    setSeconds(seconds + 1);
    console.log(seconds)
  };
  setInterval(() => {
    // startTimer();
  }, 1000);
  
  return <div className={style.counter}>05:{seconds}</div>;
};

export default Counter;
