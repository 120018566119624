import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import style from "./Expired.module.scss";

const Expired = () => {
  const location = useLocation();
  const navigate = useNavigate();
  navigate(location.pathname, { replace: true });
  return (
    <div className={style.expired}>
      <div className={style.content_wrapper}>
        <div className={style.title}>Session Expired</div>
        <div className={style.description}>
          Go back to the app and reinitialize the process
        </div>
      </div>
    </div>
  );
};

export default Expired;
