import React, { useEffect, useState } from "react";
import BrokerCard from "../../components/broker-card";
import { useHistory, useNavigate } from "react-router-dom";
import Loading from "../../components/loading";
import { useData } from "../../services/useData";
import style from "./Brokers.module.scss";

const Brokers = () => {
  const [brokers, setBrokers] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  setTimeout(() => {
    setShowLoading(false);
  }, 500);
  const BROKERS = [
    { name: "axis" },
    { name: "axis" },
    { name: "axis" },
    { name: "axis" },
    { name: "axis" },
    { name: "axis" },
  ];
  const data = useData("/interface/brokers?agent=&args=&kwargs=");
  useEffect(() => {
    data.then((res) => {
      setBrokers(res.results);
    });
  }, []);
  const expandToggler = () => {
    setShowMore(!showMore);
  };
  const router = useNavigate();
  const cardClick = (data) => {
    if (data.action === "form") {
      router(`/form/${data.broker_slug}/login`);
    }
    if (data.action === "redirection") {
      window.location.href=data.redirect.url;
    }
  };
  return (
    <div className={style.brokers}>
    {showLoading && <Loading />}
      <div className={style.title}>Login with Your Broker</div>
      <div className={style.sub_title}>
        Connect your broker account to transact in stocks, ETFs and ALPHAQ
      </div>
      <div
        className={`${style.brokers_wrapper} ${showMore ? style.expanded : ""}`}
      >
        <div className={`row ${style.row}`}>
        {!brokers &&<div>
          No Brokers found
        </div>}
          {brokers && Object.entries(brokers).map((content) => {
            let key = content[0]; 
            let item = content[1]
              return (
                <div
                  className={`col-4 ${style.card_wrapper}`}
                  key={`broker${key}`}
                  onClick={() => {
                    cardClick(item);
                  }}
                >
                  <BrokerCard data={item} />
                </div>
              );
            })}
        </div>
      </div>
      {Object.entries(brokers).length > 4 && (
        <div className={style.show_more} onClick={expandToggler}>
          show {showMore ? "less" : "more"}
        </div>
      )}
    </div>
  );
};

export default Brokers;
