import React from 'react'
import Loading from '../../components/loading'

const Home = () => {
  return (
    <div>
    <Loading />
    {setTimeout(()=>{
       window.location.href="/brokers/secured-access/protected"
    },1000)}
    </div>
  )
}

export default Home;