import React from "react";
import style from "./Loading.module.scss";
import { BiWifi2 } from "react-icons/bi";
import { BiServer } from "react-icons/bi";
import Footer from "../footer";
const Loading = () => {
  return (
    <div className={style.loading}>
      <div className={style.box}>
        <div className={style.body}>
          <div className={style.top}>
            <span className={`${style.icon} ${style.rotate}`}>
              <BiWifi2 />
            </span>
            <span className={style.dots}></span>
            <span className={style.icon}>
              <BiServer />
            </span>
          </div>
          <div className={style.center}>Processing your order for ALPHAQ</div>
          <div className={style.bottom}>
            Please continue placing the order on Upstox.
          </div>
        </div>
        <div className={style.footer}>
        <Footer />
        </div>
      </div>
    </div>
  );
};

export default Loading;
