import React, { useEffect, useState } from "react";
import { useLocation, useParams, useRoutes } from "react-router-dom";
import Button from "../../components/button";
import InputField from "../../components/input-field";
import { Formik } from "formik";
import style from "./Form.module.scss";
import * as Yup from "yup";
import Loading from "../../components/loading";
import { useData } from "../../services/useData";
import brokerContext from "../../context/brokerContext";

const Form = () => {
  const [showLoading, setShowLoading] = useState(true);
  setTimeout(() => {
    setShowLoading(false);
  }, 500);
  let { slug } = useParams();
  const handleFormSubmit = (values) => {
    let FORMATTEDVALUE = {
      user_id: null,
      password: null,
      google_authkey: null,
      dob: "YYYY-MM-DD",
      access_token: null,
      two_factor_auth: null,
      consumer_key: null,
      consumer_secret: null,
      broker: null,
      ...values,
    };
    console.log(FORMATTEDVALUE)
  };
  const INITIALVALUES = {};
  return (
    <>
      {showLoading && <Loading />}
      <brokerContext.Consumer>
        {(context) => (
          <div className={style.form}>
            <div className={style.title}>Form</div>
            <div className={style.sub_title}>Enter the details to continue</div>
            <div className={style.form_wrapper}>
              <Formik onSubmit={handleFormSubmit} initialValues={INITIALVALUES}>
                {({ results, handleChange, handleBlur, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    {context.brokers &&
                      context.brokers[slug].fields &&
                      context.brokers[slug].fields.length > 0 &&
                      context.brokers[slug].fields.map((item, index) => {
                        return (
                          <div className={style.input} key={`input-${index}`}>
                            <InputField
                              name={item.code}
                              placeholder={item.label}
                              type={item.type}
                              help_text={item.help_text}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        );
                      })}
                    <div className={style.button_wrapper}>
                      <Button type="submit">Submit</Button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </brokerContext.Consumer>
    </>
  );
};

export default Form;
