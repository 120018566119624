import React, { useEffect, useState } from "react";
import brokerContext from "../../context/brokerContext";
import { useData } from "../../services/useData";
const ContextProvider = (props) => {
  const [brokers, setBrokers] = useState();
    const data = useData("/interface/brokers?agent=&args=&kwargs=");
    useEffect(() => {
      data.then((res) => {
        setBrokers(res.results);
      });
    }, []);
  return <brokerContext.Provider value={{brokers:brokers}}>{props.children}</brokerContext.Provider>;
};

export default ContextProvider;
